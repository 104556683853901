:global {
  html {
    height: 100%;
  }

  body {
    background: #0771b8;
    color: #333;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  h1.app-name {
    color: white;
    font-size: 48px;
    font-weight: bold;
    text-shadow: 0 1px 5px #444;
    margin-top: calc(50vh - 260px);
    margin-bottom: 40px;
  }

  #app-root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .account-forms {
    background: white;
    border-radius: 10px;
    width: 375px;
    min-height: 200px;

    form {
      display: flex;
      flex-direction: column;
      padding: 10px;

      > div {
        margin-bottom: 10px;
      }

      > button {
        margin-top: 10px;
      }

      .half-text {
        width: 50%;
      }
    }
  }
}

.newsletter {
  font-size: 0.9em;

  small {
    font-size: 0.6em;
    margin-top: -5px;
    display: block;
    color: #666;
  }
}

p.tos {
  text-align: center;
  margin-top: 20px;
}
